import { BaseError } from "make-error-cause";

const voxVivaErrorSymbol = Symbol("VoxVivaError");
const notFoundErrorSymbol = Symbol("NotFoundError");
const invalidCommentaryGroupErrorSymbol = Symbol("InvalidCommentaryGroupError");

/**
 * The root of our custom error hierarchy.
 *
 * Only use this for operational errors. For the distinction between operation errors and programmer errors, read:
 * https://www.joyent.com/node-js/production/design/errors
 *
 * For 'programmer errors', just use the normal Error class, and let the program crash.
 */
export class VoxVivaError extends BaseError {
  // See https://github.com/supermacro/neverthrow/issues/451
  public readonly voxVivaError = voxVivaErrorSymbol;
}

export class NotFoundError extends VoxVivaError {
  // See https://github.com/supermacro/neverthrow/issues/451
  public readonly notFoundError = notFoundErrorSymbol;

  public readonly response = { status: 404 };
}

export class InvalidCommentaryGroupError extends VoxVivaError {
  // See https://github.com/supermacro/neverthrow/issues/451
  public readonly invalidCommentaryGroupError =
    invalidCommentaryGroupErrorSymbol;

  public readonly jsonData: string;

  constructor(message: string, cause: Error | undefined, jsonData: string) {
    super(message, cause);
    this.jsonData = jsonData;
  }
}
